import React, { useEffect, useRef, useState, useCallback } from "react"
import { uniqueId } from "lodash"
import './hs-form-styles.scss';

function generateUniqueId() {
  let id = uniqueId();
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  while (id.length < 10) {
    id += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return id;
}

const useHubSpotForm = formOptions => {
  const [loaded, setLoaded] = useState(false)
  const formRef = useRef(null)

  const unq = generateUniqueId();
  const elementId = `react-hs-form-container--${unq}-${formOptions?.formId || "noid"}`;

  const createForm = useCallback(async () => {

    if (!window.hbspt || !formRef.current) return

    try {
      window.hbspt.forms.create({
        ...formOptions,
        target: `body #${formRef.current.id}`,
        onFormReady: $form => {
          setLoaded(true);
          if (formOptions?.onFormReady) {
            formOptions.onFormReady($form)
          }
        },
        onFormSubmit: form => {
          // console.log('onFormSubmit', form)
        }
      });
      // console.log('FORM IS CREATED', formRef.current.id);
    } catch (error) {
      console.log('creating form lead to error', error)
    }
  }, [formOptions]);

  const loadScript = async () => {
    try {
      if (window.hbspt) {
        createForm()
        return
      } else {
        const script = document.createElement("script")
        script.defer = true
        script.async = true
        script.onload = createForm
        script.src = "https://js.hsforms.net/forms/embed/v2.js";
        document.head.appendChild(script);
      }
    } catch (error) {
      console.log('loading script lead to error', error)
    }
  };

  useEffect(() => {
    loadScript()
  }, [loadScript]);

  const element = (props) => (
    <>
      <div
        ref={formRef}
        id={elementId}
        className={`${props?.wrapperClass ? props.wrapperClass : ""} hs-form-v2`}
        style={{ display: loaded ? "block" : "none" }}
      ></div>
      {!loaded && <div>{props?.loader || <span>Loading...</span>}</div>}
    </>
  );

  return { element, formRef, loaded }
}

export default useHubSpotForm
