import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './advancedButtonStyles.scss';
import { ELEMENT_TYPES, getKadenceBlock } from '../utils';

export const AdvancedButton = (props) => {
    const button = getKadenceBlock(props, ELEMENT_TYPES.BUTTON);

    useEffect(() => {

        // const window.querySelector(button.id)
        const handleAnchorClick = (event) => {

            const el = event.currentTarget;
            const link = el.querySelector('a');
            const hrefAnchor = link?.getAttribute('href')?.startsWith('#');
            const href = link?.getAttribute('href');
            const id = href?.substring(1);

            const scrollToElement = document?.getElementById(id);
            if (hrefAnchor && scrollToElement) {
                event.preventDefault();
                const headerHeight = document?.querySelector('.site-header').offsetHeight; // Get the height of the header
                const elementPosition = scrollToElement.getBoundingClientRect().top + window.scrollY;
                const offsetPosition = Math.round(elementPosition - (headerHeight + 40));

                // scrollToElement.scrollIntoView({ top: offsetPosition, behavior: 'smooth' });
                if (window) {
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }
        };

        const link = document?.querySelector(`.${button.cl} a`);
        const btn = document?.querySelector(`.${button.cl}`);
        const hrefAnchor = link?.getAttribute('href')?.startsWith('#');

        if (hrefAnchor) {
            btn.addEventListener('click', handleAnchorClick);
        };

        return () => {
            if (link) {
                btn.removeEventListener('click', handleAnchorClick);
            }
        };
    }, [button.cl]);


    return (<>
        {button.style}
        {button.component}
    </>
    );
};

AdvancedButton.propTypes = {
    attrs: PropTypes.object
}
