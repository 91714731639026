import ReactHtmlParser from 'react-html-parser';
import classname from 'classnames';
import { default as minifyCssString } from 'minify-css-string'

const ALIGN_SELF = {
    middle: 'center',
    top: 'start',
    bottom: 'end'
}

const FLEX_DIRECTION = {
    horizontal: 'row',
    vertical: 'column'
}


export const ELEMENT_TYPES = {
    RAW_LAYOUT: 'kadence/rowlayout',
    COLUMN: 'kadence/column',
    BUTTON: 'kadence/button',
    TEXT: 'kadence/advancedheading',
    IMAGE: 'kadence/image',
    LOTTIE: 'kadence/lottie',
    SPACER: 'kadence/spacer'
}

export const ELEMENT_ATTRIBUTES = {
    [ELEMENT_TYPES.COLUMN]: {
        tag: 'div',
        id_prefix: 'kt-adv-column',
        class_prefix: 'kadence-column'
    },
    [ELEMENT_TYPES.BUTTON]: {
        tag: 'div',
        id_prefix: 'kb-advancedbtn',
        class_prefix: 'kt-btns'
    },
    [ELEMENT_TYPES.TEXT]: {
        tag: true,
        id_prefix: 'kb-advancedheading',
        class_prefix: 'kt-adv-heading'
    },
    [ELEMENT_TYPES.IMAGE]: {
        tag: true,
        id_prefix: 'kb-image',
        class_prefix: 'kb-image'
    },
    [ELEMENT_TYPES.LOTTIE]: {
        tag: true,
        id_prefix: 'kb-lottie-container',
        class_prefix: 'kb-lottie-container'
    },
    [ELEMENT_TYPES.SPACER]: {
        tag: 'div',
        id_prefix: 'kb-spacer',
        class_prefix: 'kt-block-spacer-'
    },
}


export const inlineStyles = (props, blockClass) => {
    // console.log(props)
    const { attrs, attrs: { uniqueID } } = props;
    if (uniqueID === '_16a2da-74') {
        // console.log(attrs)
    }
    const mobile = `
        @media (max-width: 767px){
        #___gatsby .${blockClass}${uniqueID} {
        ${attrs.mobileSize ? `font-size: ${attrs.mobileSize}px;` : ''}
        ${attrs.mobileLineHeight ? `line-height: ${attrs.mobileLineHeight}px;` : ''}
        ${attrs.mobileAlign ? `text-align: ${attrs.mobileAlign};` : ''}
        ${attrs.mobileMargin ? attrs.mobileMargin[0] && `margin-top: ${attrs.mobileMargin[0]}px;` : ''}
        ${attrs.mobileMargin ? attrs.mobileMargin[1] && `margin-right: ${attrs.mobileMargin[1]}px;` : ''}
        ${attrs.mobileMargin ? attrs.mobileMargin[2] && `margin-bottom: ${attrs.mobileMargin[2]}px;` : ''}
        
        ${attrs.bottomMarginM || attrs.bottomMarginM === 0 ? `margin-bottom: ${attrs.bottomMarginM}px !important;` : ''}
        ${attrs.topMarginM || attrs.topMarginM === 0 ? `margin-top: ${attrs.topMarginM}px !important;` : ''}
        ${attrs.leftMarginM || attrs.leftMarginM === 0 ? `margin-left: ${attrs.leftMarginM}px !important;` : ''}
        ${attrs.rightMarginM || attrs.rightMarginM === 0 ? `margin-right: ${attrs.rightMarginM}px !important;` : ''}

        ${attrs.mobileMargin ? attrs.mobileMargin[3] && `margin-left: ${attrs.mobileMargin[3]}px;` : ''}
        ${attrs.mobilePadding ? attrs.mobilePadding[0] && `padding-top: ${attrs.mobilePadding[0]}px;` : ''}
        ${attrs.mobilePadding ? attrs.mobilePadding[1] && `padding-right: ${attrs.mobilePadding[1]}px;` : ''}
        ${attrs.mobilePadding ? attrs.mobilePadding[2] && `padding-bottom: ${attrs.mobilePadding[2]}px;` : ''}
        ${attrs.mobilePadding ? (attrs.mobilePadding[3] || attrs.mobilePadding[3] === 0) && `padding-left: ${attrs.mobilePadding[3]}px;` : ''}

        ${attrs.topPaddingM || attrs.topPaddingM === 0 ? `padding-top: ${attrs.topPaddingM}px;` : ''}
        ${attrs.rightPaddingM || attrs.rightPaddingM === 0 ? `padding-right: ${attrs.rightPaddingM}px;` : ''}
        ${attrs.bottomPaddingM || attrs.bottomPaddingM === 0 ? `padding-bottom: ${attrs.bottomPaddingM}px;` : ''}
        ${attrs.leftPaddingM || attrs.leftPaddingM === 0 ? `padding-left: ${attrs.leftPaddingM}px;` : ''}

        ${attrs.direction ? attrs.direction[2] && `flex-direction: ${FLEX_DIRECTION[attrs.direction[2]]};` : ''}
        ${attrs.textAlign && attrs.textAlign[2] ? `text-align: ${attrs.textAlign[2]};` : ''}
        ${attrs.justifyContent ? `justify-content: ${attrs.justifyContent[2]};` : ''}
        ${attrs?.vsmobile ? 'display: none !important;' : ''}
        ${attrs?.maxWidth ? `max-width: 100%; margin-left: auto; margin-right: auto;` : ''}

        ${attrs?.mobileBorderWidth ? attrs.mobileBorderWidth?.[0] && `border-top: ${attrs.mobileBorderWidth[0] !== '' ? `${attrs.mobileBorderWidth[0]}px solid` : "none"};` : ''}
        ${attrs?.mobileBorderWidth ? attrs.mobileBorderWidth?.[1] && `border-right: ${attrs.mobileBorderWidth[1] !== '' ? `${attrs.mobileBorderWidth[1]}px solid` : "none"};` : ''}
        ${attrs?.mobileBorderWidth ? attrs.mobileBorderWidth?.[2] && `border-bottom: ${attrs.mobileBorderWidth[2] !== '' ? `${attrs.mobileBorderWidth[2]}px solid` : "none"};` : ''}
        ${attrs?.mobileBorderWidth ? attrs.mobileBorderWidth?.[3] && `border-left: ${attrs.mobileBorderWidth[3] !== '' ? `${attrs.mobileBorderWidth[3]}px solid` : "none"};` : ''}
        }
    }
    `;
    const tablet = `@media (min-width: 768px) and (max-width: 1024px) {
       #___gatsby .${blockClass}${uniqueID} {
            ${attrs.tabletSize ? `font-size: ${attrs.tabletSize}px;` : ''}
            ${attrs.tabletAlign ? `text-align: ${attrs.tabletAlign};` : ''}
            ${attrs.tabLineHeight ? `line-height: ${attrs.tabLineHeight}px;` : ''}

            ${attrs.tabletMargin ? attrs.tabletMargin[0] && `margin-top: ${attrs.tabletMargin[0]}px;` : ''}
            ${attrs.tabletMargin ? attrs.tabletMargin[1] && `margin-bottom: ${attrs.tabletMargin[1]}px;` : ''}
            ${attrs.tabletMargin ? attrs.tabletMargin[2] && `margin-bottom: ${attrs.tabletMargin[2]}px;` : ''}
            ${attrs.tabletMargin ? attrs.tabletMargin[3] && `margin-left: ${attrs.tabletMargin[3]}px;` : ''}

            ${attrs.bottomMarginT ? `margin-bottom: ${attrs.bottomMarginT}px !important;` : ''}
            ${attrs.topMarginT ? `margin-top: ${attrs.topMarginT}px !important;` : ''}
            ${attrs.leftMarginT ? `margin-left: ${attrs.leftMarginT}px !important;` : ''}
            ${attrs.rightMarginT ? `margin-right: ${attrs.rightMarginT}px !important;` : ''}

            ${attrs.tabletPadding && (attrs.tabletPadding[0] || attrs.tabletPadding[0] === 0) ? `padding-top: ${attrs.tabletPadding[0]}px;` : ''}
            ${attrs.tabletPadding && (attrs.tabletPadding[1] || attrs.tabletPadding[1] === 0) ? `padding-right: ${attrs.tabletPadding[1]}px;` : ''}
            ${attrs.tabletPadding && (attrs.tabletPadding[2] || attrs.tabletPadding[2] === 0) ? `padding-bottom: ${attrs.tabletPadding[2]}px;` : ''}
            ${attrs.tabletPadding && (attrs.tabletPadding[3] || attrs.tabletPadding[3] === 0) ? `padding-left: ${attrs.tabletPadding[3]}px;` : ''}

            ${attrs.direction ? attrs.direction[1] && `flex-direction: ${FLEX_DIRECTION[attrs.direction[1]]};` : ''}
            ${attrs.textAlign ? `text-align: ${attrs.textAlign[1]};` : ''}
            ${attrs.justifyContent ? `justify-content: ${attrs.justifyContent[1]};` : ''}
            ${attrs?.vstablet ? 'display: none !important;' : ''}
            ${attrs?.maxWidth ? `max-width: 100%; margin-left: auto; margin-right: auto;` : ''}

            ${attrs?.tabletBorderWidth ? attrs.tabletBorderWidth?.[0] !== '' && `border-top: ${attrs.tabletBorderWidth[0]}px solid;` : 'border-top: none;'}
            ${attrs?.tabletBorderWidth ? attrs.tabletBorderWidth?.[1] !== '' && `border-right: ${attrs.tabletBorderWidth[1]}px solid;` : 'border-right: none;'}
            ${attrs?.tabletBorderWidth ? attrs.tabletBorderWidth?.[2] !== '' && `border-bottom: ${attrs.tabletBorderWidth[2]}px solid;` : 'border-bottom: none;'}
            ${attrs?.tabletBorderWidth ? attrs.tabletBorderWidth?.[3] !== '' && `border-left: ${attrs.tabletBorderWidth[3]}px; solid` : 'border-left: none;'}
           
        }
    }`;
    const desktop = `
    #___gatsby .${blockClass}${uniqueID} {
            ${attrs.size ? `font-size: ${attrs.size}px;` : ''}
            ${attrs.lineHeight ? `line-height: ${attrs.lineHeight}px;` : ''}
            ${attrs.align ? `text-align: ${attrs.align};` : ''}
            ${attrs.textAlign ? `text-align: ${attrs.textAlign[0]};` : ''}

            ${attrs.color ? `color: ${attrs.color};` : ''}
            ${attrs.bgColor ? `background: ${attrs.bgColor};` : ''}
            ${attrs.background ? `background: ${attrs.background};` : ''}

            ${attrs.topMargin || attrs.topMargin === 0 ? `margin-top: ${attrs.topMargin}px !important;` : ''}
            ${attrs.rightMargin || attrs.rightMargin === 0 ? `margin-right: ${attrs.rightMargin}px !important;` : ''}
            ${attrs.bottomMargin || attrs.bottomMargin === 0 ? `margin-bottom: ${attrs.bottomMargin}px !important;` : ''}
            ${attrs.leftMargin || attrs.leftMargin === 0 ? `margin-left: ${attrs.leftMargin}px !important;` : ''}

            ${attrs.padding && attrs.padding[0] ? `padding-top: ${attrs.padding[0]}px;` : ''}
            ${attrs.padding && attrs.padding[1] ? `padding-right: ${attrs.padding[1]}px;` : ''}
            ${attrs.padding && attrs.padding[2] ? `padding-bottom: ${attrs.padding[2]}px;` : ''}
            ${attrs.padding && attrs.padding[3] ? `padding-left: ${attrs.padding[3]}px;` : ''}
            
            /* ROW LAYOUT */
            ${attrs.topPadding || attrs.topPadding === 0 ? `padding-top: ${attrs.topPadding}px;` : ''}
            ${attrs.rightPadding || attrs.rightPadding === 0 ? `padding-right: ${attrs.rightPadding}px;` : ''}
            ${attrs.bottomPadding || attrs.bottomPadding === 0 ? `padding-bottom: ${attrs.bottomPadding}px;` : ''}
            ${attrs.leftPadding || attrs.leftPadding === 0 ? `padding-left: ${attrs.leftPadding}px;` : ''}


            ${attrs.bgImg ? `background-image: url(${attrs.bgImg});` : ''}
            ${attrs.bgImgPosition ? `background-position: ${attrs.bgImgPosition};` : 'background-position: center;'}
            ${attrs.bgImgRepeat ? `background-repeat: ${attrs.bgImgRepeat};` : ''}
            ${`background-size: ${attrs.bgImgSize || 'cover'};`}
            

            ${attrs.backgroundImg && attrs.backgroundImg[0] && attrs.backgroundImg[0].bgImg ? `background-image: url(${attrs.backgroundImg[0].bgImg});` : ``}
            ${attrs.backgroundImg && attrs.backgroundImg[0] && attrs.backgroundImg[0].bgImgSize ? `background-size: ${attrs.backgroundImg[0].bgImgSize};` : ``}
            ${attrs.backgroundImg && attrs.backgroundImg[0] && attrs.backgroundImg[0].bgImgRepeat ? `background-repeat: ${attrs.backgroundImg[0].bgImgRepeat};` : ``}
            
            ${attrs.verticalAlignment ? `align-self: ${ALIGN_SELF[attrs.verticalAlignment]};` : ''}

            ${attrs.direction ? attrs.direction[0] && `flex-direction: ${FLEX_DIRECTION[attrs.direction[0]]};` : ''}
            ${attrs.justifyContent ? `justify-content: ${attrs.justifyContent[0]};` : ''}

         
           ${attrs?.borderRadius ? attrs?.borderRadius?.[0] && `border-top-left-radius: ${attrs.borderRadius[0]}px;` : ''}
           ${attrs?.borderRadius ? attrs?.borderRadius?.[1] && `border-top-right-radius: ${attrs.borderRadius[1]}px;` : ''}
           ${attrs?.borderRadius ? attrs?.borderRadius?.[2] && `border-bottom-right-radius: ${attrs.borderRadius[2]}px;` : ''}
           ${attrs?.borderRadius ? attrs?.borderRadius?.[3] && `border-bottom-left-radius: ${attrs.borderRadius[1]}px;` : ''}
           
           ${attrs.border ? `border-color: ${attrs.border};` : ''}
           ${attrs?.maxWidth ? `max-width: ${attrs.maxWidth}px; margin-left: auto; margin-right: auto;` : ''}
    }
    `;
    const desktopplus = `@media (min-width: 1024px) {
            #___gatsby .${blockClass}${uniqueID} {
            ${attrs?.vsdesk ? 'display: none !important;' : ''}
            ${attrs?.borderWidth ? attrs.borderWidth?.[0] && `border-top: ${attrs.borderWidth[0]}px solid;` : ''}
            ${attrs?.borderWidth ? attrs.borderWidth?.[1] && `border-right: ${attrs.borderWidth[1]}px solid;` : ''}
            ${attrs?.borderWidth ? attrs.borderWidth?.[2] && `border-bottom: ${attrs.borderWidth[2]}px solid;` : ''}
            ${attrs?.borderWidth ? attrs.borderWidth?.[3] && `border-left: ${attrs.borderWidth[3]}px solid;` : ''}
            
            }
        }`;

    const str = minifyCssString(`<style>${desktop}${tablet}${mobile}${desktopplus}</style>`);

    return ReactHtmlParser(str);
}

function recursiveFilterStyleSearch(arr, id) {
    let style = false;
    arr.forEach(function filter(item) {
        if (style) return style;
        if (item?.props?.id && item.props.id === id) {
            style = item;
        } else if (!style && item?.props?.children && Array.isArray(item.props.children) && item.props.children.length > 0) {
            item.props.children.forEach(filter);
        }
    });
    return style;
}

function recursiveFilterBlockSearch(arr, cl) {
    let block = false;
    arr.forEach(function filter(item) {
        if (block) return block;
        if (item && item.props && item.props.className && item.props.className.includes(cl)) {
            block = item;
        } else if (!block && item && item.props && item.props.children && item.props.children && Array.isArray(item.props.children) && item.props.children.length > 0) {
            item.props.children.forEach(filter);
        }
    });
    return block;
}



export const getKadenceBlock = (props, type) => {
    const { attrs, content } = props;
    // const { uniqueID } = attrs;
    const allComponents = ReactHtmlParser(content);
    // _e327ef-58
    // if (uniqueID === '_e327ef-58') {
    //     console.log('btn', props);
    // }
    const id = `${ELEMENT_ATTRIBUTES[type].id_prefix}${attrs.uniqueID}`;
    const cl = `${ELEMENT_ATTRIBUTES[type].class_prefix}${attrs.uniqueID}`;


    const style = recursiveFilterStyleSearch(allComponents, id);
    const component = recursiveFilterBlockSearch(allComponents, cl);



    return {
        style,
        component,
        id,
        cl
    }
}

// function findColumn(parsedContent, uniqueID) {
//     const entries = Object.entries(parsedContent);
//     for (let i = 0; i < entries.length; i += 1) {
//         const [treeKey, treeValue] = entries[i];
//         if (treeKey) { }
//         if (treeValue !== null && treeValue.blockName === ELEMENT_TYPES.RAW_LAYOUT) {
//             return findColumn(treeValue.innerBlocks, uniqueID)
//         }
//         if (treeValue !== null && treeValue.blockName === ELEMENT_TYPES.COLUMN) {
//             return treeValue.attrs;
//         }
//     }
// }

export const getKadenceBlockStyles = (props) => {
    const { blockName, attrs, content } = props;
    if (!ELEMENT_TYPES.hasOwnProperty(blockName)) {
        return;
    }

    const allComponents = ReactHtmlParser(content);

    const id = `${ELEMENT_ATTRIBUTES[blockName].id_prefix}${attrs.uniqueID}`;
    // let extractedAttribtes = {};

    const style = recursiveFilterStyleSearch(allComponents, id);
    // const test = findColumn(parsedContent, attrs.uniqueID);
    // console.log(test)


    return {
        style
    }
}

export const parseAttributes = (props) => {
    const { attrs, innerHTML } = props;
    const colLayout = attrs.colLayout ? `${attrs.colLayout}-${attrs.columns}` : '';

    // separate containers
    const innerHtmlParsed = ReactHtmlParser(innerHTML);
    const containerHtmlParsed = innerHtmlParsed[0];
    const innerContainerParsed = innerHtmlParsed[0].props.children[0];
    const columnWrapParsed = innerContainerParsed.props.children[0];

    // objects for Classes
    const container = {
        classes: {
            [colLayout]: true,
        },
        id: containerHtmlParsed.props.id || ''
    };

    const innerContainer = {
        classes: {},
        id: innerContainerParsed.props.id || ''
    };

    const columnsContainer = {
        classes: {},
        id: columnWrapParsed.props.id || ''

    };

    // Maping classes to objects
    containerHtmlParsed.props.className.split(" ").forEach((item) => { container.classes[item] = true; });
    innerContainerParsed.props.className.split(" ").forEach((item) => { innerContainer.classes[item] = true; });
    columnWrapParsed.props.className.split(" ").forEach((item) => { columnsContainer.classes[item] = true; });


    const result = {
        container: {
            classes: classname(container.classes)
        },
        innerContainer: {
            classes: classname(innerContainer.classes)
        },
        columnsContainer: {
            classes: classname(columnsContainer.classes)
        }
    };

    return result;
}